import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line
import { useTracking } from '@lib/tracking';

import ModuleZone from '~moduleZone';
import Layout from '@components/layout';
import { PageMeta } from '@components/pageMeta';
import { PageHeader } from '@components/pageHeader';

const IndexPage = ({ data: { page } }) => {
  const { pageModules, seo, title } = page || {};
  const { track } = useTracking();

  useEffect(() => {
    track('Viewed Page', {
      page: title,
    });
  }, []);
  return (
    <Layout>
      <PageMeta {...seo} />
      <PageHeader />
      {pageModules && <ModuleZone {...pageModules} />}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query homePageQuery {
    page: sanityHomePage {
      title
      seo {
        ...SEO
      }
      pageModules: modules {
        ...PageModules
      }
    }
  }
`;
